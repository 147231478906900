import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import qs from 'qs'
import React, { useContext, useEffect } from 'react'

import { BROWSER } from '~/config'
import { makeSchema } from '~/schema'
import { AnimationContext, LocaleContext } from '~/src/contexts'

import BasicPage from '../BasicPage'
import PostPage from '../PostPage/Post'

const schema = makeSchema()

const strapiApiMap = {
  'api::landing-page.landing-page': 'Page',
  'api::landing-post.landing-post': 'Post',
  'api::shared-block.shared-block': 'Block',
}

const PreviewPage = () => {
  const { locale } = useContext(LocaleContext)

  const params = BROWSER ?
    qs.parse(window.location.search, { ignoreQueryPrefix: true }) : null
  const type = strapiApiMap[params?.['strapi.api']]
  const pageId = type === 'Page' ? params['strapi.id'] : null
  const postId = type === 'Post' ? params['strapi.id'] : null
  const blockId = type === 'Block' ? params['strapi.id'] : null

  const query = gql`
    query($locale: I18NLocaleCode!, $pageId: ID, $postId: ID, $blockId: ID) {
      page: landingPage(locale: $locale, id: $pageId) {
        data {
          attributes {
            ... LandingPage
          }
        }
      }

      post: landingPost(locale: $locale, id: $postId) {
        data {
          attributes {
            ... LandingPost
          }
        }
      }

      block: sharedBlock(locale: $locale, id: $blockId) {
        data {
          attributes {
            title
            blocks {
              ... on SharedBlockBlocksDynamicZone {
                __typename
                ... BlockBubbleBlock
                ... BlockCardsBlock
                ... BlockCarouselBlock
                ... BlockCatalogBlock
                ... BlockDeliverBlock
                ... BlockEmbedVideoBlock
                ... BlockExpandBlock
                ... BlockExpandBlockV2
                ... BlockFormBlock
                ... BlockFormattedTableBlock
                ... BlockFullWidthImageBlock
                ... BlockIframeBlock
                ... BlockImageBlock
                ... BlockImageCardsBlock
                ... BlockSimpleTextBlock
                ... BlockSpaceBlock
                ... LatestPostsBlock
                ... TwoColumnsBlock
                ... BlockIconCardsBlock
                ... BlockListBlock
                ... BlockParagraphsBlock
                ... BlockThreeStepsBlock
                ... BlockTabsBlock
                ... BlockTwoColumnsCheckListBlock
                ... BlockTwoColumnsImageBlock
                ... BlockTwoColumnsMediaBlock
                ... BlockItemsBlock
                ... BlockRegistrationBlock
                ... BlockNotificationBlock
                ... BlockThreeImagesBlock
                ... BlockSliderBlock
                ... BlockImageCarouselBlock
              }
            }
            createdAt
            updatedAt
            publishedAt
            locale
          }
        }
      }

      headerV2: headerV2S(locale: $locale) {
        data {
          attributes {
            ... HeaderV2
          }
        }
      }

      footerV3: footerV3S(locale: $locale) {
        data {
          attributes {
            ... FooterV3
          }
        }
      }

      # Not needed, but must be included in the query
      # to avoid unused fragment error
      header: header(locale: $locale) {
        data {
          attributes {
            ... Header
          }
        }
      }
      footer: footer(locale: $locale) {
        data {
          attributes {
            ... Footer
          }
        }
      }
      settings: setting(locale: $locale) {
        data {
          id
          attributes {
            ... Setting
          }
        }
      }
    }
    ${schema}
  `

  const { disableAnimation } = useContext(AnimationContext)
  useEffect(() => {
    disableAnimation()
  }, [])

  // Only run query if page id is available
  const { loading, error, data } = useQuery(query, {
    skip: !pageId && !postId && !blockId,
    fetchPolicy: 'no-cache',
    variables: {
      pageId,
      postId,
      blockId,
      locale: params ? params['strapi.locale'] : locale,
    },
  })
  if (
    loading || error || !data ||
    (!data.page.data && !data.post.data && !data.block.data)
  ) return null

  const pageAttribute = data.page?.data?.attributes || data.block?.data?.attributes

  return (
    <>
      {pageAttribute ? (
        <BasicPage
          layoutContext={{
            headerV2: data.headerV2,
            footer: data.footer?.data?.attributes,
            footerV3: data.footerV3,
            notification: pageAttribute?.meta?.notification,
          }}
          pageContext={{
            settingContext: {
              navigationHeader: pageAttribute?.meta?.header,
              footerType: pageAttribute?.meta?.footer,
            },
            blocks: pageAttribute?.blocks.map((block) => {
              const {
                background_color,
                gradient,
                transparent,
                identity,
              } = block.option || {}
              return {
                typeName: block.__typename,
                data: block,
                backgroundColor: background_color,
                gradient,
                transparent,
                identity,
              }
            }),
          }}
        />
      ) : null}
      {data.post?.data ? (
        <PostPage
          pageContext={{
            post: {
              ...data.post?.data?.attributes,
              contents: data.post?.data?.attributes?.contents?.map((content) => ({
                ...content,
                typeName: content.__typename,
              })),
              tags: data.post?.data?.attributes?.tags?.length > 0 ? data.post.data.attributes.tags.split(';').reduce((acc, val) => {
                const tag = val.trim()
                if (tag.length > 0) acc.push(tag)
                return acc
              }, []) : null,
            },
          }}
        />
      ) : null}
    </>
  )
}

export default PreviewPage
