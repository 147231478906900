module.exports = `
  fragment UploadFileEntityResponse on @UploadFileEntityResponse {
    data {
      ... UploadFileEntity
    }
  }

  fragment UploadFileEntity on @UploadFileEntity {
    id
    attributes {
      ... UploadFile
    }
  }

  fragment UploadFile on @UploadFile {
    name
    caption
    width
    height
    url
  }

  fragment InternalAccordion on @ComponentInternalAccordion {
    title
    items {
      ... FragmentParagraph
    }
  }

  fragment InternalAccordionV2 on @ComponentInternalAccordionV2 {
    title
    items {
      ... InternalAccordionItem
    }
  }

  fragment InternalAccordionItem on @ComponentInternalAccordionItem {
    paragraph {
      ... FragmentParagraph
    }
    image {
      ... FragmentImage
    }
  }

  fragment InternalCardsBlockCard on @ComponentInternalCardsBlockCard {
    title
    content
    link {
      ... FragmentLink
    }
  }

  fragment InternalCardsIconCardsBlock on @ComponentInternalCardsIconCardsBlock {
    title
    content
    button {
      ... FragmentLink
    }
    icon {
      ... InternalIcon
    }
  }

  fragment InternalCarouselItem on @ComponentInternalCarouselItem {
    title
    subtitle
    description
    image {
      ... FragmentImage
    }
    link {
      ... FragmentLink
    }
    icon {
      ... InternalIcon
    }
    is_default
    color
    buttons {
      ... FragmentLink
    }
  }

  fragment InternalFormField on @ComponentInternalFormField {
    type
    required
    label
    value_name
    value
  }

  fragment InternalHtmlText on @ComponentInternalHtmlText {
    content
  }

  fragment InternalSimpleText on @ComponentInternalSimpleText {
    content
  }

  fragment InternalIcon on @ComponentInternalIcon {
    icon {
      ... FragmentIcon
    }
    image {
      ... FragmentImage
    }
  }

  fragment InternalImageCardsBlockItem on @ComponentInternalImageCardsBlockItem {
    item_image {
      ... UploadFileEntityResponse
    }
    title
    content
    link {
      ... FragmentLink
    }
  }

  fragment InternalSteps on @ComponentInternalSteps {
    label
    description
  }

  fragment InternalTabsBlockTabs on @ComponentInternalTabsBlockTabs {
    content
    link {
      ... FragmentLink
    }
    button {
      ... FragmentLink
    }
    icon {
      ... InternalIcon
    }
    images {
      ... FragmentImage
    }
  }

  fragment InternalMedia on @ComponentInternalMedia {
    image {
      ... FragmentImage
    }
    html_text
  }

  fragment InternalSliderBlockImages on @ComponentInternalSliderBlockImages {
    image {
      ... UploadFileEntityResponse
    }
    url: link
  }

  fragment InternalSliderBlockItems on @ComponentInternalSliderBlockItems {
    content
    author
    job_title
    image {
      ... UploadFileEntityResponse
    }
    button {
      ... FragmentLink
    }
  }

  fragment InternalTableRow on @ComponentInternalTableRow {
    columns {
      ... FragmentTableColumn
    }
  }

  fragment InternalTranslation on @ComponentInternalTranslation {
    key
    value
  }

  fragment InternalSeoStructuredData on @ComponentInternalSeoStructuredData {
    type
    price_range
    same_as
  }
`
