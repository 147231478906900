module.exports = `
  fragment TypePageMeta on @ComponentTypePageMeta {
    slug
    seo_title
    seo_description
    seo_structured_data {
      ... InternalSeoStructuredData
    }
    og_title
    og_description
    og_image {
      ... UploadFileEntityResponse
    }
    header
    footer
    notification {
      ... BlockNotificationBlock
    }
  }

  fragment TypePostMeta on @ComponentTypePostMeta {
    slug
    og_title
    og_description
    og_image {
      ... UploadFileEntityResponse
    }
    header
    call_to_action {
      ... FragmentCallToAction
    }
  }

  fragment TypeBlockOption on @ComponentTypeBlockOption {
    background_color
    gradient
    transparent
    identity,
  }

  fragment TypeSharedBlock on @ComponentTypeSharedBlock {
    block {
      data {
        attributes {
          blocks {
            ... on @SharedBlockBlocksDynamicZone {
              __typename
              ... BlockBubbleBlock
              ... BlockCardsBlock
              ... BlockCarouselBlock
              ... BlockCatalogBlock
              ... BlockDeliverBlock
              ... BlockEmbedVideoBlock
              ... BlockExpandBlock
              ... BlockExpandBlockV2
              ... BlockFormBlock
              ... BlockFormattedTableBlock
              ... BlockFullWidthImageBlock
              ... BlockIframeBlock
              ... BlockImageBlock
              ... BlockImageCardsBlock
              ... BlockSimpleTextBlock
              ... BlockSpaceBlock
              ... LatestPostsBlock
              ... TwoColumnsBlock
              ... BlockIconCardsBlock
              ... BlockListBlock
              ... BlockParagraphsBlock
              ... BlockThreeStepsBlock
              ... BlockTabsBlock
              ... BlockTwoColumnsCheckListBlock
              ... BlockTwoColumnsImageBlock
              ... BlockTwoColumnsMediaBlock
              ... BlockItemsBlock
              ... BlockRegistrationBlock
              ... BlockNotificationBlock
              ... BlockThreeImagesBlock
              ... BlockSliderBlock
              ... BlockImageCarouselBlock
            }
          }
        }
      }
    }
  }

  fragment BlockBubbleBlock on @ComponentBlockBubbleBlock {
    id
    image {
      ... FragmentImage
    }
    quote
    content
    button {
      ... FragmentLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockCardsBlock on @ComponentBlockCardsBlock {
    id
    header_content
    cards {
      ... InternalCardsBlockCard
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockCarouselBlock on @ComponentBlockCarouselBlock {
    id
    carousel_items {
      ... InternalCarouselItem
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockCatalogBlock on @ComponentBlockCatalogBlock {
    id
    title
    products {
      ... FragmentImageLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockDeliverBlock on @ComponentBlockDeliverBlock {
    id
    title
    subtitle
    boxes {
      ... InternalHtmlText
    }
    description
    link {
      ... FragmentLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockEmbedVideoBlock on @ComponentBlockEmbedVideoBlock {
    id
    title
    description
    embed_content
    layout
    text_align
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockExpandBlock on @ComponentBlockExpandBlock {
    id
    header
    sections {
      ... InternalAccordion
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockExpandBlockV2 on @ComponentBlockExpandBlockV2 {
    id
    header
    subheader
    items {
      ... InternalAccordionV2
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockFormBlock on @ComponentBlockFormBlock {
    id
    title
    form {
      ... FragmentForm
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockFormattedTableBlock on @ComponentBlockFormattedTableBlock {
    id
    title
    rows (pagination: { limit: 50 }) {
      ... InternalTableRow
    }
    buttons {
      ... FragmentLink
    }
    hover_effect
    mobile_layout
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockFullWidthImageBlock on @ComponentBlockFullWidthImageBlock {
    id
    image {
      ... FragmentImage
    }
    responsive_image {
      ... FragmentResponsiveImage
    }
    background_overlay
    title
    height
    lg_height
    md_height
    sm_height
    xs_height
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockIframeBlock on @ComponentBlockIframeBlock {
    id
    header
    auto_height
    enable_click
    link {
      ... FragmentLink
    }
    title
    cta_link {
      ... FragmentLink
    }
    coverage {
      ... InternalSimpleText
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockImageBlock on @ComponentBlockImageBlock {
    id
    placeholder
    image {
      ... FragmentImage
    }
    responsive_image {
      ... FragmentResponsiveImage
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockImageCardsBlock on @ComponentBlockImageCardsBlock {
    id
    title
    card_items {
      ... InternalImageCardsBlockItem
    }
    imageCardsLayout: layout
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockSimpleTextBlock on @ComponentBlockSimpleTextBlock {
    id
    title
    description
    icon_link {
      ... FragmentIconLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockSpaceBlock on @ComponentBlockSpaceBlock {
    id
    spacing_height
  }

  fragment LatestPostsBlock on @ComponentBlockLatestPostsBlock {
    id
    title
    amount
    read_more_text
    categories
    option {
      ... TypeBlockOption
    }
  }

  fragment TwoColumnsBlock on @ComponentBlockTwoColumnsBlock {
    id
    header
    title
    description
    align_text
    image {
      ... FragmentImage
    },
    image_shadow
    image_border_radius
    two_columns_layout
    icon_links {
      ... FragmentIconLink
    },
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockIconCardsBlock on @ComponentBlockIconCardsBlock {
    id
    header
    icon_cards_layout
    cards {
      ... InternalCardsIconCardsBlock
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockListBlock on @ComponentBlockListBlock {
    id
    title
    subtitle
    list_block_layout
    image_effect
    link {
      ... FragmentLink
    }
    items {
      ... InternalSimpleText
    }
    image {
      ... FragmentImage
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockParagraphsBlock on @ComponentBlockParagraphsBlock {
    id
    title
    animation
    paragraphs (pagination: { pageSize: 50 }) {
      ... FragmentParagraph
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockThreeStepsBlock on @ComponentBlockThreeStepsBlock {
    id
    title
    subtitle
    steps {
      ... InternalSteps
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockTabsBlock on @ComponentBlockTabsBlock {
    id
    title
    tabs {
      ... InternalTabsBlockTabs
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockTwoColumnsCheckListBlock on @ComponentBlockTwoColumnsCheckListBlock {
    id
    title
    content
    list_title
    list_content
    button {
      ... FragmentLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockTwoColumnsImageBlock on @ComponentBlockTwoColumnsImageBlock {
    id
    content
    buttons {
      ... FragmentLink
    }
    text_alignment
    twoColumnsImageLayout: layout
    image {
      ... FragmentImage
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockTwoColumnsMediaBlock on @ComponentBlockTwoColumnsMediaBlock {
    id
    title
    content
    buttons {
      ... FragmentLink
    }
    twoColumnsMediaLayout: layout
    background_image {
      ... UploadFileEntityResponse
    }
    background_overlay
    media {
      ... InternalMedia
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockItemsBlock on @ComponentBlockItemsBlock {
    id
    title
    icon_shadow
    items {
      ... FragmentItems
    }
    button {
      ... FragmentLink
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockRegistrationBlock on @ComponentBlockRegistrationBlock {
    id
    title
    image {
      ... FragmentImage
    }
    form {
      ... FragmentForm
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockNotificationBlock on @ComponentBlockNotificationBlock {
    id
    title
    background_color
    icon {
      ... UploadFileEntityResponse
    }
    content
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockThreeImagesBlock on @ComponentBlockThreeImagesBlock {
    id
    title
    image_1 {
      ... FragmentImage
    }
    image_2 {
      ... FragmentImage
    }
    image_3 {
      ... FragmentImage
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockSliderBlock on @ComponentBlockSliderBlock {
    id
    title
    images {
      ... InternalSliderBlockImages
    }
    items {
      ... InternalSliderBlockItems
    }
    option {
      ... TypeBlockOption
    }
  }

  fragment BlockImageCarouselBlock on @ComponentBlockImageCarouselBlock {
    id
    title
    subtitle
    infinite_loop
    autoplay
    autoplay_interval
    button {
      ... FragmentLink
    }
    carousel_items {
      ... FragmentImage
    }
    option {
      ... TypeBlockOption
    }
  }
`
