module.exports = `
  fragment LandingPage on @LandingPage {
    meta {
      ... TypePageMeta
    }
    blocks {
      ... on @LandingPageBlocksDynamicZone {
        __typename
        ... BlockBubbleBlock
        ... BlockCardsBlock
        ... BlockCarouselBlock
        ... BlockCatalogBlock
        ... BlockDeliverBlock
        ... BlockEmbedVideoBlock
        ... BlockExpandBlock
        ... BlockExpandBlockV2
        ... BlockFormBlock
        ... BlockFormattedTableBlock
        ... BlockFullWidthImageBlock
        ... BlockIframeBlock
        ... BlockImageBlock
        ... BlockImageCardsBlock
        ... BlockSimpleTextBlock
        ... BlockSpaceBlock
        ... LatestPostsBlock
        ... TwoColumnsBlock
        ... BlockIconCardsBlock
        ... BlockListBlock
        ... BlockParagraphsBlock
        ... BlockThreeStepsBlock
        ... BlockTabsBlock
        ... BlockTwoColumnsCheckListBlock
        ... BlockTwoColumnsImageBlock
        ... BlockTwoColumnsMediaBlock
        ... BlockItemsBlock
        ... BlockRegistrationBlock
        ... BlockNotificationBlock
        ... BlockThreeImagesBlock
        ... BlockSliderBlock
        ... BlockImageCarouselBlock
        ... TypeSharedBlock
      }
    }
    createdAt
    updatedAt
    publishedAt
    locale
  }

  fragment LandingPost on @LandingPost {
    title
    tags
    cover_image {
      ... FragmentImage
    }
    created_time
    meta {
      ... TypePostMeta
    }
    contents {
      ... on @LandingPostContentsDynamicZone {
        __typename
        ... FragmentImage
        ... FragmentParagraph
        ... FragmentTextImage
        ... FragmentForm
        ... FragmentIframe
      }
    }
  }

  fragment Header on @Header {
    navigations {
      ... FragmentLink
    }
    login_button {
      ... FragmentLink
    }
    sign_up_button {
      ... FragmentLink
    }
  }

  fragment HeaderV2 on @HeaderV2 {
    key
    navigations {
      ... FragmentMenuItem
    }
    login_button {
      ... FragmentLink
    }
    sign_up_label
    sign_up_buttons {
      ... FragmentLink
    }
  }

  fragment FooterV3 on @FooterV3 {
    key
    logo {
      ... FragmentImage
    }
    columns {
      ... FragmentMenuItem
    }
    buttons {
      ... FragmentImage
    }
    copyright {
      ... InternalSimpleText
    }
    socials {
      ... FragmentIconLink
    }
  }

  fragment Footer on @Footer {
    logo {
      ... FragmentImage
    }
    columns {
      ... FragmentMenuItem
    }
    copyright
    socials {
      ... FragmentIconLink
    }
  }

  fragment Setting on @Setting {
    blog_cover_image {
      ... FragmentImage
    }
    og_image {
      ... FragmentImage
    }
    translations {
      ... InternalTranslation
    }
    locale
  }
`
