module.exports = `
  fragment FragmentForm on @ComponentFragmentForm {
    title
    notes
    fields {
      ... InternalFormField
    }
    facebook_pixel_event_name
    submit_button_label
    submit_sheet_name
    post_submission_message
    post_submission_link {
      ... FragmentLink
    }
  }

  fragment FragmentIcon on @ComponentFragmentIcon {
    name
    vendor
    type
  }

  fragment FragmentIconLink on @ComponentFragmentIconLink {
    icon {
      ... FragmentIcon
    }
    link {
      ... FragmentLink
    }
  }

  fragment FragmentImage on @ComponentFragmentImage  {
    alt
    label
    description
    src {
      ... UploadFileEntityResponse
    }
    link {
      ... FragmentLink
    }
  }

  fragment FragmentResponsiveImage on @ComponentFragmentResponsiveImage  {
    xs {
      ... UploadFileEntityResponse
    }
    sm {
      ... UploadFileEntityResponse
    }
    md {
      ... UploadFileEntityResponse
    }
    lg {
      ... UploadFileEntityResponse
    }
    xl {
      ... UploadFileEntityResponse
    }
  }

  fragment FragmentImageLink on @ComponentFragmentImageLink {
    image {
      ... FragmentImage
    }
    link {
      ... FragmentLink
    }
  }

  fragment FragmentLink on @ComponentFragmentLink {
    text
    identity
    url
    target
    event_name
  }

  fragment FragmentLinkWithLabel on @ComponentFragmentLinkWithLabel {
    label
    link {
      ... FragmentLink
    }
  }

  fragment FragmentMenuItemChildren on @ComponentFragmentMenuItemChildren {
    label
    link {
      ... FragmentLink
    }
    items {
      ... FragmentLinkWithLabel
    }
  }

  fragment FragmentMenuItem on @ComponentFragmentMenuItem {
    label
    link {
      ... FragmentLink
    }
    items {
      ... FragmentMenuItemChildren
    }
  }

  fragment FragmentTableColumn on @ComponentFragmentTableColumn {
    content
    image {
      ... FragmentImage
    }
    background_color
    links {
      ... FragmentLink
    }
  }

  fragment FragmentParagraph on @ComponentFragmentParagraph {
    header
    subheader
    content
  }

  fragment FragmentItems on @ComponentFragmentItems {
    label
    description
    link {
      ... FragmentLink
    }
    icon {
      ... InternalIcon
    }
  }

  fragment FragmentCallToAction on @ComponentFragmentCallToAction {
    title
    content
    buttons {
      ... FragmentLink
    }
  }

  fragment FragmentTextImage on @ComponentFragmentTextImage {
    text
    layout
    image {
      ... FragmentImage
    }
  }

  fragment FragmentIframe on @ComponentFragmentIframe {
    header_content
    url
    auto_height
    enable_click
  }
`
