const internals = require('./internals')
const fragments = require('./fragments')
const blocks = require('./blocks')
const collections = require('./collections')

const makeSchema = (prefix = null) => {
  const replacement = prefix ? `${prefix}_` : ''

  const schemas = [collections, blocks, fragments, internals].map((s) => {
    return s.replace(/@/g, replacement)
  })

  return schemas.join('')
}

module.exports = {
  makeSchema,
}
